import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '../../models/page';
import {Observable} from 'rxjs';
import {DataListResponse} from '../../models/data-list-response';
import {User} from '../../models/user';
import {HelperService} from '../helper.service';
import {DataResponse} from '../../models/data-response';
import {AuthUser} from '../../models/auth-user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  endPoint = `/users`;

  constructor(private http: HttpClient) {
  }

  getList(page: Page): Observable<DataListResponse<User>> {
    return this.http.get<DataListResponse<User>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('size', page.size.toString())
        .set('sortColumn', page.sortColumn)
        .set('searchCriteria', page.searchCriteria)
    });
  }

  async getByKeyclockId(keycloakId): Promise<DataResponse<User>> {
    return this.http.get<DataResponse<User>>(`${HelperService.getAPIUrl(this.endPoint)}/${keycloakId}`).toPromise();

  }

  get(keycloakId): Observable<DataResponse<AuthUser>> {
    return this.http.get<DataResponse<AuthUser>>(`${HelperService.getAPIUrl(this.endPoint)}/${keycloakId}`);
  }

  create(user: User): Observable<DataListResponse<User>> {
    return this.http.post<DataListResponse<User>>(HelperService.getAPIUrl(this.endPoint), user);
  }

  update(keycloakId: string, value: User): Observable<DataListResponse<User>> {

    return this.http.put<DataListResponse<User>>(`${HelperService.getAPIUrl(this.endPoint)}/${keycloakId}`, value);
  }

  delete(keycloakId: string): Observable<DataListResponse<User>> {
    return this.http.delete<DataListResponse<User>>(`${HelperService.getAPIUrl(this.endPoint)}/${keycloakId}`);
  }
}
