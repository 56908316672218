import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {Building} from '../../../../../../models/building';
import {Table} from '../../../../../../models/table';
import {Page} from '../../../../../../models/page';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../../../../../services/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {BuildingService} from '../../../../../../services/building/building.service';
import {ElectroDeviceInventoryItem} from '../../../../../../models/electro-device-inventory-item';
import {ElectroDeviceInventoryItemService} from '../../../../../../services/electro-device-inventory-item.service';
import {environment} from '../../../../../../../environments';
import {Image} from '../../../../../../models/image';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import {AuthUser} from '../../../../../../models/auth-user';

@Component({
  selector: 'app-view-electro-device-inventory',
  templateUrl: './view-electro-device-inventory.component.html',
  styleUrls: ['./view-electro-device-inventory.component.css']
})
export class ViewElectroDeviceInventoryComponent implements OnInit {

  @Input() instance = null;
  @ViewChild('instanceForm', {static: true}) instanceForm;

  mailSending = false;
  inventoryItem = null;
  inventoryType = 0;

  ticket = {
    subject: '',
    body: '',
    inventoryItemId: null,
    emailTo: '',
    emailCc: '',
  };

  user: AuthUser;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  uploadAllTriggered = false;

  optionsInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Image, MineTypeEnum.Image_Png],
    disableMultipart: false
  };

  imageList: any;

  createWaiting = false;
  columnMode = ColumnMode;
  building: Building = null;
  electroDeviceInventoryItem = new ElectroDeviceInventoryItem();
  electroDeviceInventoryItemId = null;

  buildingId: string = null;
  instanceId: string = null;

  imageUploading = false;

  imageUploadModalOptions = {
    title: 'Bild Hochladen',
    cancelButtonColor: '#111',
    showCancelButton: false,
    showConfirmButton: false,
    showLoaderOnConfirm: true,
    focusCancel: true,
    allowOutsideClick: () => false,
    preConfirm: () => {
    },
    onClose: () => {
      this.galleryImages = [];
      this.imageUploader.removeAllFromQueue();
    },
    width: '1000px'
  };

  imageGalleryModalOptions = {
    cancelButtonColor: '#111',
    showCancelButton: false,
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    width: '700px',
  };

  ticketModalOptions = {
    title: 'SUPPORT TICKET',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Senden',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '1000px',
    preConfirm: () => {
    },
    allowOutsideClick: () => true
  };

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Gerätekategorie', prop: 'deviceType', width: 20},
      {name: 'Gerätehersteller', prop: 'deviceManufacturer', width: 20},
      {name: 'Gerätemodell', prop: 'deviceModel', width: 20}
    ],
    rowList: new Array<ElectroDeviceInventoryItem>()
  };

  public _album: Array<any> = [];

  private temp: any;


  exporting = false;
  reportInfoReady = false;
  exportFile = null;

  exportCSVModalOptions = {
    title: 'Prüfung exportieren',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Export',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
    },
    preConfirm: () => {
    },
    allowOutsideClick: () => !this.exporting
  };


  nextTestingDateList = [
    {id: 0, name: 'Keine Auswahl', value: false},
    {id: 1, name: '3 Monate', value: false},
    {id: 2, name: '6 Monate', value: false},
    {id: 3, name: '1 Jahr', value: true},
    {id: 4, name: '2 Jahre', value: false},
    {id: 5, name: '3 Jahre', value: false},
    {id: 6, name: '4 Jahre', value: false},
    {id: 7, name: '5 Jahre', value: false}
  ];
  testingResultList = [
    {id: 0, name: 'Nicht Bestanden', value: 0},
    {id: 1, name: 'Bestanden', value: 1},
    {id: 2, name: 'Fehlend', value: 2},
  ];

  yesNoList = [
    {id: 0, name: 'Ja', value: true},
    {id: 1, name: 'Nein', value: false},
  ];

  protectionClassList = [
    {id: 0, name: 'Schutzklasse I'},
    {id: 1, name: 'Schutzklasse II'},
    {id: 2, name: 'Schutzklasse III'},
  ];

  CEList = [
    {id: 0, name: 'Ja', value: true, default: true},
    {id: 1, name: 'Nein', value: false, default: false},
  ];

  propertyList = [
    {id: 0, name: 'Einrichtung'},
    {id: 1, name: 'Bewohner'},
    {id: 2, name: 'Unternehmen'},
  ];

  controlTypeList = [
    {id: 0, name: 'elektr. 230V'},
    {id: 1, name: 'elektr. 400V'},
  ];

  constructor(public uploader: HttpClientUploadService,
              public readonly swalTargets: SwalPartialTargets,
              private route: ActivatedRoute,
              private electroDeviceInventoryItemService: ElectroDeviceInventoryItemService,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private buildingService: BuildingService,
              public imageUploader: HttpClientUploadService) {
  }

  ngOnInit() {

    this.user = this.authenticationService.getAuthUser;

    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.instance = null;
    this.imageUploader.queue = [];
    this.imageUploader.onCancel$.subscribe(
      (data: FileItem) => {

      });

    this.imageUploader.onDropError$.subscribe(
      (err) => {
      });

    this.imageUploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.imageUploader.onSuccess$.subscribe(
      (data: any) => {

        this.galleryImages = data.body.data;

        const vedin = data.body.data.fileName.split('_')[0];

        this.table.rowList.forEach(value => {
          if (value.vedin === vedin) {
            value.images.push(data.body.data.fileName);
          }
        });

        data.item.remove();
      }
    );

    this.imageUploader.onAddToQueue$.subscribe(
      () => {

      }
    );

    this.buildingId = this.route.snapshot.paramMap.get('id');
    this.instanceId = this.route.snapshot.paramMap.get('instanceId');

    this.buildingService.get(this.route.snapshot.paramMap.get('id')).subscribe(response => {
      this.building = response.data;
    });

    this.getPage(0);
    this.user = this.authenticationService.getAuthUser;
  }

  delete(rowList: Array<ElectroDeviceInventoryItem>) {

    const that = this;

    rowList.forEach(function (row) {

      that.electroDeviceInventoryItemService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          // that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });
    });
  }

  upload(item: FileItem) {
    item.upload({
      method: 'POST',
      url: this.electroDeviceInventoryItemService.uploadImageUrl(this.electroDeviceInventoryItemId)
    });
  }

  uploadAll() {
    this.uploadAllTriggered = true;
    this.imageUploader.uploadAll({
      method: 'POST',
      url: this.electroDeviceInventoryItemService.uploadImageUrl(this.electroDeviceInventoryItemId)
    });
  }

  uploadImageAll() {
    this.uploadAllTriggered = true;
    this.imageUploader.uploadAll({
      method: 'POST',
      url: this.electroDeviceInventoryItemService.uploadImageUrl(this.electroDeviceInventoryItemId)
    });
  }

  activeRemoveAllBtn(): boolean {
    return this.imageUploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.imageUploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.imageUploader.queue.some((item: FileItem) => item.uploadInProgress);
  }


  getMediaResource(name: string) {
    return environment.apis.backend + '/media/uploads/' + name;
  }

  deleteImage(image: Image) {
    const itemId = this.route.snapshot.paramMap.get('id');

    this.electroDeviceInventoryItemService.deleteImage(itemId, image).subscribe(dataResponse => {
      this.instance.imageList = dataResponse.data;
    });
  }

  onActivate(event: any) {

  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;

    this.getPage(0);
  }

  onDelete(rowList) {

    this.delete(rowList);
  }

  updateFilter(event) {

    const val = event.target.value.toLowerCase();

    // filter our data
    this.table.rowList = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
        d.iso.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // this.dataTable.offset = 0;
  }


  getPage(pageNumber: any) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    const buildingId = this.route.snapshot.paramMap.get('id');
    const electroDeviceInventoryId = this.route.snapshot.paramMap.get('inventoryId');

    this.electroDeviceInventoryItemService.getList(buildingId, electroDeviceInventoryId, this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.temp = data;
      this.table.rowList = [...data];

      if (data.length > 0) {
        this.inventoryType = data[0].electroDeviceInventory.inventoryType;
      }

      this.table.loading = false;
      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message);
      }

    });
  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: number = null) {

    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  onBeforeOpen($event: BeforeOpenEvent) {
  }

  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr((size * -1), size);
  }

  getReportName(row: any) {

    const vedin = row.vedin;
    const buildingNumber = this.padLeft(row.electroDeviceInventory.building.internalNumber, '0', 2);
    const customerNumber = row.electroDeviceInventory.building.company.customerNumber;
    const barcode = row.barcode;

    return `${environment.apis.backend}/media/uploads/devices/check-reports/${vedin}_${customerNumber}_${buildingNumber}_${barcode}.pdf`;
  }

  getReportName2(row: any) {

    const vedin = row.vedin;
    const buildingNumber = this.padLeft(row.electroDeviceInventory.building.internalNumber, '0', 2);
    const customerNumber = row.electroDeviceInventory.building.company.customerNumber;
    const barcode = row.barcode;

    return `${environment.apis.backend}/media/uploads/devices/check-reports/${vedin}_${customerNumber}_${buildingNumber}_${barcode}_SuF.pdf`;
  }

  getReportName3(row: any) {

    const vedin = row.vedin;
    const buildingNumber = this.padLeft(row.electroDeviceInventory.building.internalNumber, '0', 2);
    const customerNumber = row.electroDeviceInventory.building.company.customerNumber;
    const barcode = row.barcode;

    return `${environment.apis.backend}/media/uploads/devices/check-reports/${vedin}_${customerNumber}_${buildingNumber}_${barcode}_bound.pdf`;
  }

  setInventoryItemId(id) {
    this.galleryImages = [];
    this.imageUploader.removeAllFromQueue();
    this.electroDeviceInventoryItemId = id;
  }

  setImageForGallery(item: ElectroDeviceInventoryItem) {

    const self = this;
    this.galleryImages = [];
    item.images.forEach(function (image) {

      const album: NgxGalleryImage = {
        url: `${environment.apis.backend}/media/uploads/devices/images/${item.vedin}/${image}`,
        big: `${environment.apis.backend}/media/uploads/devices/images/${item.vedin}/${image}`,
        small: `${environment.apis.backend}/media/uploads/devices/images/${item.vedin}/${image}`,
        medium: `${environment.apis.backend}/media/uploads/devices/images/${item.vedin}/${image}`,
        label: item.vedin,
        description: `<strong>${item.deviceType} / ${item.deviceManufacturer} / ${item.deviceModel} / ${image}</strong><br>${item.comment}`
      };

      self.galleryImages.push(album);
    });
  }

  onSendMail(row: ElectroDeviceInventoryItem) {
    this.ticket.emailTo = this.user.portalInfo.company.supportMailTo;
    this.ticket.emailCc = this.user.portalInfo.company.supportMailCc;
    this.inventoryItem = row;
    this.ticket.inventoryItemId = row.id;
  }

  sendMail() {

    this.mailSending = true;

    this.electroDeviceInventoryItemService.sendMail(this.ticket).subscribe(resp => {
      this.ticket.body = '';
      this.ticket.subject = '';
      this.mailSending = false;
    });
  }

  searchRemote($event: KeyboardEvent) {
    if ($event.code === 'Enter') {
      this.getPage(0);
    }
  }

  export(type: string) {

    this.exportFile = null;
    this.exporting = true;

    const self = this;

    const electroDeviceInventoryId = this.route.snapshot.paramMap.get('inventoryId');

    if (type === 'csv') {
      setTimeout(function () {
        self.reportInfoReady = true;
      }, 2000, self);

      this.electroDeviceInventoryItemService.exportInventory(electroDeviceInventoryId).subscribe(resp => {
        this.exporting = false;
        this.exportFile = resp.data.fileName;
      });

    }
  }

  getExportFile() {
    return this.exportFile ? `${environment.apis.backend}/media/temp/reports/${this.exportFile}` : 'no-file-found';
  }

  cancelExport() {
    this.swalTargets.cancelButton().click();
    this.exporting = false;
    this.exportFile = null;
  }

  formatBarcode(barcode: any) {
    return ('000000' + barcode).slice(-6);
  }

  getPropertyName(property: number) {
    const _property = this.propertyList.find(item => item.id === property);
    return _property ? _property.name : '';
  }
}
