import {ElectroDeviceTestingDevice} from './electro-device-testing-device';
import {ElectroDeviceTesting} from './electro-device-testing';

export class ElectroDeviceTestingItem {
  id: number;
  vedin?: string;
  barcode?: string;
  nextTestingDateType?: number;
  comment?: string;
  registerNumber?: number;
  createdAt?: string;
  updatedAt?: string;
  location?: string;
  images?: any[] = [];
  protectionClass?: string;
  deviceType?: string;
  deviceManufacturer?: string;
  nextDGUVV3?: string;
  deviceModel?: string;
  serialNumber?: string;
  installation?: string;
  testingDevice?: ElectroDeviceTestingDevice;
  testingDeviceId?: number;
  isCESymbolExist?: boolean;
  controlType?: number;
  property?: number; // 0, 1 or 2 currently
  testStatus?: number;
  electroDeviceTesting?: ElectroDeviceTesting;
}
