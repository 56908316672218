import {Component, Input, OnInit, Output} from '@angular/core';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ElectroDeviceTestingService} from '../../services/electro-device-testing.service';

@Component({
  selector: 'app-import-electro-device-testing',
  templateUrl: './import-electro-device-testing.component.html',
  styleUrls: ['./import-electro-device-testing.component.css']
})
export class ImportElectroDeviceTestingComponent implements OnInit {

  @Input() testingId!: number;
  uploadableFileName !: string;
  @Output()

  pdfImporter = {
    fileNames: Array<string>()
  };

  result = null;
  importing = false;
  importedSuccessfully = false;

  optionPdfInput: InputFileOptions = {
    multiple: false,
    accept: [MineTypeEnum.Text_Csv],
    disableMultipart: false
  };

  totalUploading = 0;

  constructor(public uploader: HttpClientUploadService,
              public readonly swalTargets: SwalPartialTargets,
              private electroDeviceTestingService: ElectroDeviceTestingService) {
  }

  ngOnInit() {

    this.pdfImporter.fileNames = new Array<string>();

    this.uploader.queue = [];
    this.uploader.onCancel$.subscribe(
      (data: FileItem) => {
      });

    this.uploader.onDropError$.subscribe(
      (err) => {
      });

    this.uploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.uploader.onSuccess$.subscribe(
      (data: any) => {
        this.totalUploading = 0;
        this.uploadableFileName = data.body.data.fileName;
      }
    );
  }

  uploadFiles(item: FileItem) {
    item.upload({
      method: 'POST',
      url: this.electroDeviceTestingService.uploadDeviceCSVUrl()
    });
  }

  activeRemoveAllBtn(): boolean {
    return this.uploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.uploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.uploader.queue.some((item: FileItem) => item.uploadInProgress);
  }

  removeCSVDocuments() {
    this.pdfImporter.fileNames = new Array<string>();
    this.uploader.queue = [];
  }

  uploadAll() {
    const that = this;
    this.totalUploading = this.uploader.queue.length;
    this.uploader.queue.forEach(function (value) {
      that.uploadFiles(value);
    });

    this.totalUploading = 0;
  }

  canImport() {
    return this.testingId && this.uploader.queue.length > 0;
  }

  upload() {
    this.importing = true;
    this.electroDeviceTestingService.upload(this.testingId, this.uploadableFileName).subscribe((data: any) => {
      this.result = data.data;

      this.importing = false;
      this.electroDeviceTestingService.newEvent('refresh-item-list');
    });
  }
}
